// assets
import {
  DashboardOutlined,
  ChromeOutlined,
  ProfileOutlined,
  QuestionOutlined,
  ScheduleOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  LoginOutlined,
  AccountBookOutlined,
  UserAddOutlined
} from '@ant-design/icons'

// icons
const icons = {
  DashboardOutlined,
  ChromeOutlined,
  QuestionOutlined,
  ScheduleOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  ProfileOutlined,
  LoginOutlined,
  AccountBookOutlined,
  AdminPanelSettingsIcon,
  TimelineIcon,
  UserAddOutlined
}
import StorefrontIcon from '@mui/icons-material/Storefront'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import AddShoppingCart from '@mui/icons-material/AddShoppingCart'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import TimelineIcon from '@mui/icons-material/Timeline'
// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const MemberMenu = {
  id: 'memberMenu',
  title: 'Main',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    },
    {
      id: 'schedule',
      title: 'Schedule',
      type: 'item',
      url: '/schedule',
      icon: icons.ScheduleOutlined
    },
    {
      id: 'appointment',
      title: 'Appointments',
      type: 'item',
      url: '/appointment',
      icon: icons.ScheduleOutlined
    },
    {
      id: 'bills',
      title: 'Bills/Payments/Credits',
      type: 'item',
      url: '/bills',
      icon: MonetizationOnIcon
    },
    {
      id: 'receipts',
      title: 'Receipts',
      type: 'item',
      url: '/receipts',
      icon: ReceiptLongIcon
    },
    {
      id: 'orders',
      title: 'Customer Orders',
      type: 'item',
      url: '/orders',
      icon: AddShoppingCart
    },
    {
      id: 'clients',
      title: 'Clients',
      type: 'item',
      url: '/clients',
      icon: PermIdentityIcon
    },
    {
      id: 'users',
      title: 'Staffs',
      type: 'item',
      url: '/users',
      icon: icons.UsergroupAddOutlined
    },
    {
      id: 'licences',
      title: 'Licences',
      type: 'item',
      url: '/licences',
      icon: CreditCardIcon
    },
    {
      id: 'products',
      title: 'Products',
      type: 'item',
      url: '/products',
      icon: StorefrontIcon
    },   
    {
      id: 'wages',
      title: 'Wages',
      type: 'item',
      url: '/wages',
      icon: AccountBookOutlined
    },
    {
      id: 'managerZone',
      title: 'Manager Zone',
      type: 'item',
      url: '/managerZone',
      icon: icons.AdminPanelSettingsIcon
    },
    {
      id: 'practitioner',
      title: 'Practitioner',
      type: 'item',
      url: '/practitioner',
      icon: icons.UserAddOutlined
    },
    {
      id: 'logout',
      title: 'LogOut',
      type: 'item',
      url: '/logout',
      icon: icons.LoginOutlined
    }
  ]
}

export default MemberMenu
