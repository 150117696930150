export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST'
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS'
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'


export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_ACTIVE = 1
export const ACCESS_TOKEN = 'access_token'

export const CALENDAR_TYPE_ALL = 'all'
export const CALENDAR_TYPE_DAILY = 'daily'
export const CALENDAR_TYPE_LICENCE = 'licence'

export const TREATMENT_SERVICEID = 1
export const PRODUCT_SEREVICEID = 2
export const CALENDAR_DISABLE_SEREVICEID = 99
export const DEFAULT_INSURANCE_COMPANY = 1
export const USER_SETTING_MODULE_SHIFT = 'shift'
export const USER_SETTING_SCHEDULE = 'schedule'
export const USER_SETTING_MODULE_START_END = 'startEndDay'
export const USER_SETTING_START_KEY = 'startDay'
export const USER_SETTING_END_KEY = 'endDay'


export const INSURANCE_PRIMARY = 1
export const INSURANCE_SECONDARY = 2

export const APPOINTMENT_STATUS_BOOKED = 1
export const APPOINTMENT_STATUS_CLOSE = 5

export const USER_PRACTITIONER = 1
export const APPOINTMENT_PRODUCT_DURATION = 60

export const RECEIPT_TYPE_MASSAGE = 1
export const RECEIPT_TYPE_ACUPUNCTURE = 2
export const RECEIPT_CATEGORY_TYPE_MASSAGE = 'Massage'
export const RECEIPT_CATEGORY_TYPE_ACUPUNCTURE = 'Acupuncture'

export const USER_TYPE_PRACTITIONER = 1
export const USER_TYPE_RECEIPTS = 2
export const USER_TYPE_MANAGER = 3
export const USER_TYPE_ADMIN = 4
export const USER_TYPE_LICENCE_OWNER = 5
export const USER_TYPE_LICENCE_ADMIN = 6
export const USER_TYPE_SUPERADMIN = 10

export const BILL_TYPE_TREAMTMENT = 1
export const BILL_TYPE_ORDER = 2
export const BILL_TYPE_RECEIPT = 3

export const REFERRAL_TYPE_ORDER = 1
export const REFERRAL_TYPE_TREATMENT = 2
export const PRODUCT_ACTIVE = 1
export const PRODUCT_DISCONTINUE = 2
export const SCHEDULE_SHIFT_PERIOD = 30

export const  CASH_PAYMENT_MEHODID =1
export const FAMLIY_CREATE_PAYMENT_METHODID =5

export const MAX_CATEGORYID_FOR_SESSION = 8