import _ from "lodash"

import {
    USER_TYPE_ADMIN,
    USER_TYPE_LICENCE_ADMIN,
    USER_TYPE_LICENCE_OWNER,
    USER_TYPE_MANAGER,
    USER_TYPE_PRACTITIONER,
    USER_TYPE_RECEIPTS,
    USER_TYPE_SUPERADMIN
} from "../constants/userConstants"

export default {
    checkAllow(sessionUser, path) {
        if (sessionUser?.typeId === USER_TYPE_SUPERADMIN) {
            return true
        }
        // for all member
        switch (path) {
            case 'booking':
            case 'general':
            case 'public':
            case 'logout':
            case '404':
                return true
        }
        let allowList = []
        switch (path) {
            case 'dashboard':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'schedule':
                allowList = [
                    USER_TYPE_PRACTITIONER,
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'appointment':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'insuranceSchedule':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER,
                    USER_TYPE_LICENCE_OWNER
                ]
                break
            case 'licenceSchedule':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER,
                    USER_TYPE_LICENCE_OWNER,
                    USER_TYPE_LICENCE_ADMIN
                ]
                break

            case 'bills':
            case 'bill':
            case 'credits':
            case 'credit':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'receipts':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER,
                    USER_TYPE_LICENCE_OWNER
                ]
                break
            case 'receipt':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'orders':
            case 'order':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'licences':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER,
                    USER_TYPE_LICENCE_OWNER,
                    USER_TYPE_LICENCE_ADMIN
                ]
                break
            case 'clients':
            case 'client':
            case 'users':
            case 'user':
            case 'licence':
            case 'products':
            case 'product':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'wages':
                allowList = [
                    USER_TYPE_PRACTITIONER,
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'wage':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'timesheets':
                allowList = [
                    USER_TYPE_PRACTITIONER,
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'timesheet':
                allowList = [
                    USER_TYPE_RECEIPTS,
                    USER_TYPE_MANAGER
                ]
                break
            case 'managerZone':
                allowList = [
                    USER_TYPE_MANAGER
                ]
                break
            case 'practitioner':
                allowList = [
                    USER_TYPE_PRACTITIONER
                ]
                break
        }
        if (sessionUser?.typeId) {
            if (_.includes(allowList, sessionUser.typeId)) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    },
    async defaultLandingPage(sessionUser) {
        let home = '/dashboard'
        switch (sessionUser?.typeId) {
            case USER_TYPE_PRACTITIONER:
                home = '/schedule'
                break
            case USER_TYPE_ADMIN:
                home = '/receipts'
                break
            case USER_TYPE_LICENCE_OWNER: {
                home = '/insuranceSchedule/' + sessionUser.id
                break
            }
            case USER_TYPE_LICENCE_ADMIN:
                home = '/licences'
        }
        return home
    }
}

