import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import MainLayout from 'layout/MainLayout'
import AuthGuard from "../guards/AuthGuards"

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/DashboardTabs')))

// render - sample page
//const UsersTable = Loadable(lazy(() => import('pages/users/UsersTable')))
const UserDetail = Loadable(lazy(() => import('pages/users/UserDetail')))
const UserEdit = Loadable(lazy(() => import('pages/users/UserEdit')))
const UserCreate = Loadable(lazy(() => import('pages/users/UserCreate')))
const UserShift = Loadable(lazy(() => import('pages/users/UserShift')))
const UserTimeOff = Loadable(lazy(() => import('pages/users/UserTimeOff')))

const ClientTable = Loadable(lazy(() => import('pages/client/ClientTable')))
const ClientDetail = Loadable(lazy(() => import('pages/client/ClientDetail')))
const ClientEdit = Loadable(lazy(() => import('pages/client/ClientEdit')))
const ClientCreate = Loadable(lazy(() => import('pages/client/ClientCreate')))

const ProductTable = Loadable(lazy(() => import('pages/product/ProductTable')))
const ProductCreate = Loadable(lazy(() => import('pages/product/ProductCreate')))
const ProductEdit = Loadable(lazy(() => import('pages/product/ProductEdit')))

const ReceiptTable = Loadable(lazy(() => import('pages/receipt/ReceiptTable')))
const ReceiptEdit = Loadable(lazy(() => import('pages/receipt/ReceiptEdit')))
const ReceiptCopy = Loadable(lazy(() => import('pages/receipt/ReceiptCopy')))
const ReceiptPrint = Loadable(lazy(() => import('pages/receipt/ReceiptPrintPanel')))
const MultiReceiptsPrint = Loadable(lazy(() => import('pages/receipt/MultiReceiptsPrintPanel')))

const BillTabs = Loadable(lazy(() => import('pages/bill/BillTabs')))
// const BillTable = Loadable(lazy(() => import('pages/bill/BillsList')))
const BillEdit = Loadable(lazy(() => import('pages/bill/BillEdit')))

const CreditTable = Loadable(lazy(() => import('pages/credit/CreditTable')))
const CreditEdit = Loadable(lazy(() => import('pages/credit/CreditEdit')))

const LicenceTable = Loadable(lazy(() => import('pages/licence/LicenceTable')))
const LicenceEdit = Loadable(lazy (() => import('pages/licence/LicenceEdit')))
const LicenceCreate = Loadable(lazy (() => import('pages/licence/LicenceCreate')))

const OrderTable = Loadable(lazy(() => import('pages/orders/OrdersTable')))
const OrderCopy = Loadable(lazy(() => import('pages/orders/OrderCopy')))
const OrderEdit = Loadable(lazy(() => import('pages/orders/OrderEdit')))

const AppointmentSchedule = Loadable(lazy(() => import('pages/calendar/AppointmentSchedule')))
const DailySchedule = Loadable(lazy(() => import('pages/calendar/DailySchedule')))
const LicenceSchedule = Loadable(lazy(() => import('pages/calendar/LicenceSchedule')))
const InsuranceSchedule = Loadable(lazy(() => import('pages/calendar/InsuranceSchedule')))

const WageTable = Loadable(lazy(() => import('pages/wage/WageTable')))
const WageEdit = Loadable(lazy(() => import('pages/wage/WageEdit')))

const StaffTabs = Loadable(lazy(() => import('pages/timesheet/StaffTabs')))
const TimesheetTable = Loadable(lazy(() => import('pages/timesheet/TimesheetTable')))
const TimesheetCreate = Loadable(lazy(() => import('pages/timesheet/TimesheetCreate')))
const TimesheetEdit = Loadable(lazy(() => import('pages/timesheet/TimesheetEdit')))

const ManagerZoneTabs = Loadable(lazy(() => import('pages/manager-zone/ManagerZoneTabs')))
const PractitionerIndex = Loadable(lazy(() => import('pages/practitioner/PractitionerIndex')))

const AuthLogout = Loadable(lazy(() => import('pages/auth/Logout')))
const Error404 = Loadable(lazy(() => import('pages/general/Error404')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element:  <AuthGuard navId={'public'} component={<MainLayout />} />,
  children: [
    {
      path: 'dashboard',
      element: <AuthGuard navId={'dashboard'} component={<DashboardDefault />} />
    },
    {
      path: 'users',
      element: <AuthGuard navId={'users'} component={<StaffTabs />} />
    },
    // {
    //   path: 'users',
    //   element: <AuthGuard navId={'users'} component={<UsersTable />} />
    // },
    {
      path: 'user/detail/:id',
      element: <AuthGuard navId={'user'} component={<UserDetail />} />
    },
    {
      path: 'user/edit/:id',
      element: <AuthGuard navId={'user'} component={<UserEdit />} />
    },
    {
      path: 'user/shift/:id',
      element: <AuthGuard navId={'user'} component={<UserShift />} />
    },
    {
      path: 'user/timeOff/:id',
      element: <AuthGuard navId={'user'} component={<UserTimeOff />} />
    },
    {
      path: 'user/create',
      element: <AuthGuard navId={'user'} component={<UserCreate />} />
    },
    {
      path: 'clients',
      element: <AuthGuard navId={'clients'} component={<ClientTable />}/>
    },
    {
      path: 'client/detail/:id',
      element: <AuthGuard navId={'client'} component={<ClientDetail />}/>
    },
    {
      path: 'client/edit/:id',
      element: <AuthGuard navId={'client'} component={<ClientEdit />} />
    },
    {
      path: 'client/create',
      element: <AuthGuard navId={'client'} component={<ClientCreate />} />
    },
    {
      path: 'products',
      element: <AuthGuard navId={'products'} component={<ProductTable />} />
    },
    {
      path: 'product/create',
      element: <AuthGuard navId={'product'} component={<ProductCreate />} />
    },
    {
      path: 'product/edit/:id',
      element: <AuthGuard navId={'product'} component={<ProductEdit />} />
    },
    {
      path: 'receipts',
      element: <AuthGuard navId={'receipts'} component={<ReceiptTable />}/>
    },
    {
      path: 'receipt/edit/:id',
      element: <AuthGuard navId={'receipt'} component={<ReceiptEdit />}/>
    },
    {
      path: 'receipt/copy/:id',
      element: <AuthGuard navId={'receipt'} component={<ReceiptCopy />} />
    },
    {
      path: 'receipt/print/:id',
      element: <AuthGuard navId={'receipt'} component={<ReceiptPrint />}/>
    },
    {
      path: 'receipt/multiprint/:ids',
      element: <AuthGuard navId={'receipt'} component={<MultiReceiptsPrint />}/>
    },
    {
      path: 'orders',
      element: <AuthGuard navId={'orders'} component={<OrderTable />}/>
    },
    {
      path: 'order/copy/:id',
      element: <AuthGuard navId={'order'} component={<OrderCopy />} />
    },
    {
      path: 'order/edit/:id',
      element: <AuthGuard navId={'order'} component={<OrderEdit />} />
    },
    {
      path: 'bills',
      element: <AuthGuard navId={'bills'} component={<BillTabs />} />
    },
    {
      path: 'bill/edit/:id',
      element: <AuthGuard navId={'bill'} component={<BillEdit />} />
    },
    {
      path: 'credits',
      element: <AuthGuard navId={'credits'} component={<CreditTable />} />
    },
    {
      path: 'credit/edit/:id',
      element: <AuthGuard navId={'credit'} component={<CreditEdit />} />
    },
    {
      path: 'licences',
      element: <AuthGuard navId={'licences'} component={<LicenceTable />} />
    },
    {
      path: 'licence/edit/:id',
      element: <AuthGuard navId={'licence'} component={<LicenceEdit />} />
    },
    {
      path: 'licence/create',
      element: <AuthGuard navId={'licence'} component={<LicenceCreate />} />
    },
    {
      path: 'schedule',
      element: <AuthGuard navId={'schedule'} component={<DailySchedule />} />
    },
    {
      path: 'appointment',
      element: <AuthGuard navId={'appointment'} component={<AppointmentSchedule />} />
    },
    {
      path: 'licenceSchedule/:licenceUserId',
      element: <AuthGuard navId={'licenceSchedule'} component={<LicenceSchedule />} />
    },
    {
      path: 'insuranceSchedule/:licenceUserId',
      element: <AuthGuard navId={'insuranceSchedule'} component={<InsuranceSchedule />} />
    },
    {
      path: 'wages',
      element: <AuthGuard navId={'wages'} component={<WageTable />} />
    },
    {
      path: 'wage/edit/:id',
      element: <AuthGuard navId={'wage'} component={<WageEdit />} />
    },
    {
      path: 'timesheets',
      element: <AuthGuard  navId={'timesheets'} component={<TimesheetTable />} />
    },
    {
      path: 'timesheet/create',
      element: <AuthGuard  navId={'timesheet'} component={<TimesheetCreate />} />
    },
    {
      path: 'timesheet/edit/:id',
      element: <AuthGuard  navId={'timesheet'} component={<TimesheetEdit />} />
    },
    {
      path: 'managerZone',
      element: <AuthGuard navId={'managerZone'} component={<ManagerZoneTabs />} />
    },
    {
      path: 'practitioner',
      element: <AuthGuard navId={'practitioner'} component={<PractitionerIndex />} />
    },
    {
      path: 'logout',
      element: <AuthGuard navId={'public'} component={<AuthLogout />} />
    },
    {
      path: '404',
      element: <AuthGuard navId={'public'} component={<Error404 />} />
    },
    {
      path: '*',
      element: <AuthGuard navId={'public'} component={<Error404 />} />
    }
  ]
}

export default MainRoutes
